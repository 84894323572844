import React, { Component } from "react";

import projects from '../projects';

import "./style/ProjectDetails.scss";

import iconDefault from "../images/icon-default.svg";
import iconWeb from "../images/icon-webdesign.svg";
import iconBranding from "../images/icon-brandidentity.svg";
import iconArtD from "../images/icon-artdirection.svg";
import iconEd from "../images/icon-edition.svg";
import iconIll from "../images/icon-illustration.svg";
import iconTattoo from "../images/icon-tattoo.svg";
import iconPrint from "../images/icon-print.svg";
import iconApp from "../images/icon-app.svg";

import arrowRight from "../images/arrow-right.svg";
import arrowRightHover from "../images/arrow-right-hover.svg";
import arrowLeft from "../images/arrow-left.svg";
import arrowLeftHover from "../images/arrow-left-hover.svg";

class ProjectDetails extends Component {
  constructor(props) {
    super(props);
    const id = this.props.match.params.projectId;
    const dataProject = projects.projects.find(elem => {
      return elem.id === id;
    })
    this.state = {
      dataProject,
      indexPictures: 0,
    };
  }

  handleLeftClick(array) {
    const { indexPictures } = this.state;
    if (indexPictures === 0) {
      this.setState({ indexPictures: array.length - 1 });
    } else {
      this.setState({ indexPictures: this.state.indexPictures - 1 });
    }
  }

  handleRightClick(array) {
    const { indexPictures } = this.state;
    if (array.length - 1 === indexPictures) {
      this.setState({ indexPictures: 0 });
    } else {
      this.setState({ indexPictures: this.state.indexPictures + 1 });
    }
  }

  render() {
    const {
      title,
      subtitle,
      category,
      description,
      mainpicture,
      picturesArray,
      appArray,
      video,
      url,
      poster,
    } = this.state.dataProject;
    const { indexPictures } = this.state;

    return (
      <section id="ProjectDetails">
        <div className="content">
          <div className="left-side col-lg-4">
            <img
              src={mainpicture}
              alt={title}
              className="miniature"
              loading="lazy"
            />
            <h1>{title}</h1>
            <h2>{subtitle}</h2>
            <p className="span">{category}</p>
            <p>{description}</p>
            {url ? (
              <a href={url} target="blank">
                <button className="span">more</button>
              </a>
            ) : null}
          </div>
          <div className="right-side col-lg-8">
            {picturesArray && picturesArray.length === 1 ? (
              <div>
                <img
                  src={picturesArray[0]}
                  alt={title}
                  className="biggest"
                  loading="lazy"
                />
              </div>
            ) : video && video.length === 1 ? (
              <video
                width="100%"
                controls
                src={video[0]}
                type="video/mp4"
                poster={poster}
              />
            ) : null}
            {video && video.length > 1 ? (
              <div>
                <video
                  width="100%"
                  controls
                  src={video[indexPictures]}
                  type="video/mp4"
                  poster={poster}
                />
                <div className="arrows">
                  <img
                    src={arrowLeft}
                    alt=""
                    className="arrow"
                    onMouseOver={(e) => (e.currentTarget.src = arrowLeftHover)}
                    onMouseOut={(e) => (e.currentTarget.src = arrowLeft)}
                    onClick={() => this.handleLeftClick(picturesArray)}
                    loading="lazy"
                  />
                  <img
                    src={arrowRight}
                    alt=""
                    className="arrow"
                    onMouseOver={(e) => (e.currentTarget.src = arrowRightHover)}
                    onMouseOut={(e) => (e.currentTarget.src = arrowRight)}
                    onClick={() => this.handleRightClick(picturesArray)}
                    loading="lazy"
                  />
                </div>
              </div>
            ) : null}
            {picturesArray && picturesArray.length > 1 ? (
              <div>
                <img
                  src={picturesArray[indexPictures]}
                  alt={title}
                  className="biggest"
                  loading="lazy"
                />
                <div className="arrows">
                  <img
                    src={arrowLeft}
                    alt=""
                    className="arrow"
                    onMouseOver={(e) => (e.currentTarget.src = arrowLeftHover)}
                    onMouseOut={(e) => (e.currentTarget.src = arrowLeft)}
                    onClick={() => this.handleLeftClick(picturesArray)}
                    loading="lazy"
                  />
                  <img
                    src={arrowRight}
                    alt=""
                    className="arrow"
                    onMouseOver={(e) => (e.currentTarget.src = arrowRightHover)}
                    onMouseOut={(e) => (e.currentTarget.src = arrowRight)}
                    onClick={() => this.handleRightClick(picturesArray)}
                    loading="lazy"
                  />
                </div>
              </div>
            ) : null}
            {appArray && appArray.length > 1 ? (
              <div>
                <img
                  src={appArray[indexPictures]}
                  alt={title}
                  className="biggestapp"
                  loading="lazy"
                />
                <div className="arrows">
                  <img
                    src={arrowLeft}
                    alt=""
                    className="arrow"
                    onMouseOver={(e) => (e.currentTarget.src = arrowLeftHover)}
                    onMouseOut={(e) => (e.currentTarget.src = arrowLeft)}
                    onClick={() => this.handleLeftClick(appArray)}
                    loading="lazy"
                  />
                  <img
                    src={arrowRight}
                    alt=""
                    className="arrow"
                    onMouseOver={(e) => (e.currentTarget.src = arrowRightHover)}
                    onMouseOut={(e) => (e.currentTarget.src = arrowRight)}
                    onClick={() => this.handleRightClick(appArray)}
                    loading="lazy"
                  />
                </div>
              </div>
            ) : null}
          </div>
        </div>

        <div className="icondiv col-lg-1 col-lg-offset-11">
          {category === "tattoo" ? (
            <img src={iconTattoo} alt="" className="icon" />
          ) : category === "web design" ? (
            <img src={iconWeb} alt="" className="icon" />
          ) : category === "branding" ? (
            <img src={iconBranding} alt="" className="icon" />
          ) : category === "edition" ? (
            <img src={iconEd} alt="" className="icon" />
          ) : category === "illustration" ? (
            <img src={iconIll} alt="" className="icon" />
          ) : category === "print" ? (
            <img src={iconPrint} alt="" className="icon" />
          ) : category === "art direction" ? (
            <img src={iconArtD} alt="" className="icon" />
          ) : category === "app" ? (
            <img src={iconApp} alt="" className="icon" />
          ) : (
            <img src={iconDefault} alt="" className="icon" />
          )}
        </div>
      </section>
    );
  }
}

export default ProjectDetails;
