import React, { Component } from "react";

import "./style/FontColors.scss";
import "./style/About.scss";

import profilepic from "../images/Illustration3-2.gif";
import iconAbout from "../images/icon-about.svg";
import linkedin from "../images/rs-in.svg";
import instagram from "../images/rs-ig.svg";
import github from "../images/rs-gh.svg";

class About extends Component {
  render() {
    return (
      <section id="About">
        <div className="content">
          <div className="About-left col-lg-4 col-md-6">
            <img src={profilepic} alt="" className="miniature" loading="lazy" />
            <p>
            Creative art director and visuel storyteller <span role="img" aria-label="Storyteller">👩🏻‍🎨</span>,
            I use to work with many companies to develop with them a unique identity
            consistent with their values, and enhance their brand image.
            </p>
            <div className="contact">
              <a href="mailto:laura.stromboni@gmail.com">
                <button className="span">Let's talk</button>
              </a>
              <div className="all-rs">
                <a
                  href="https://www.linkedin.com/in/laurastromboni/"
                  target="blank"
                  class="sm-links"
                >
                  <img src={linkedin} alt="" className="rs" />
                </a>
                <a
                  href="https://www.instagram.com/laurastromboni/"
                  target="blank"
                  class="sm-links"
                >
                  <img src={instagram} alt="" className="rs" />
                </a>
                {/* <a
                  href="https://github.com/laurastromboni"
                  target="blank"
                  class="sm-links"
                >
                  <img src={github} alt="" className="rs" />
                </a> */}
              </div>
            </div>
          </div>

          <div className="About-right col-lg-8 col-md-6">
            <h1>I'm always curious about new projects or ideas. </h1>
            <h2>. feel free to text me</h2>
          </div>
        </div>

        <div className="icondiv col-lg-1">
          <img src={iconAbout} alt="" className="icon" />
        </div>
      </section>
    );
  }
}

export default About;
