import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import "./style/FontColors.scss";
import "./style/Header.scss";

import logo from "../images/laurastromboni.svg";
import menu from "../images/menu-ouvert.svg";
import menuhover from "../images/menu-ouvert.svg";

class Header extends Component {
  constructor(props) {
    super(props);
    this.handleMouseHover = this.handleMouseHover.bind(this);
    this.state = {
      isHovering: false
    };
  }

  handleMouseHover() {
    this.setState(this.toggleHoverState);
  }

  render() {
    return (
      <section id="Header">
        <NavLink exact to="/">
          <img src={logo} className="logo" alt="Laura Stromboni" />
        </NavLink>
        <div className="menu" onClick={() => this.props.toggleFunction()}>
          {this.state.isHovering && (
            <img src={menuhover} className="menu-img" alt="menu" />
          )}
          {!this.state.isHovering && (
            <img src={menu} className="menu-img" alt="menu" />
          )}
        </div>
      </section>
    );
  }
}

export default Header;
