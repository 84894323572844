export const projects = [
  // WEBDESIGN
  {
    id: 'roarweb',
    title: "roar",
    subtitle: ". for real animals lovers",
    category: "webdesign",
    description:
      "Roar is an ethical french brand selling fake fur clothes.",
    mainpicture: "/images/Webdesign-Roar.jpg",
    picturesArray: [
      "/images/Roar-screen1.png",
      "/images/Roar-screen2.gif",
      "/images/Roar-screen3.gif",
      "/images/Roar-screen4.gif",
      "/images/Roar-screen5.png"
    ],
    url: "",
  },{
    id: 'mdvsite',
    title: "my dear vagina",
    subtitle: ". ig account & book",
    category: "webdesign",
    description:
      "Manifesto of modern times, My Dear Vagina is a real declaration of love made to women by creative people who seek to show their beauty on paper. This call to difference, this hymn to nature has not any other purpose than to celebrate the female sex in all its splendor.",
    mainpicture: "/images/Code-MDV.jpg",
    picturesArray: [
      "/images/MDV-website-1.png",
      "/images/MDV-website-2.gif",
      "/images/MDV-website-3.gif",
    ],
    url: "https://www.mydearvagina.com",
  },
  {
    id: 'culot',
    title: "culot",
    subtitle: ". content creation agency",
    category: "webdesign",
    description:
      "Culot is a media and content creation agency which talks about love, genders and sexuality.",
    mainpicture: "/images/Code-Culot.jpg",
    picturesArray: [
      "/images/Culot-1.gif",
      "/images/Culot-2.png",
      "/images/Culot-3.gif",
      "/images/Culot-4.gif",
    ],
    url: "https://www.culotcreative.com",
  },
  {
    id: 'wecode',
    title: "we",
    subtitle: ". mobile dating app",
    category: "webdesign",
    description:
      "We is a new dating app. Andy Warhol said once « People should fall in love with their eyes closed », We did it.",
    mainpicture: "/images/Code-We.jpg",
    picturesArray: [
      "/images/We-screen1.gif",
      "/images/We-screen2.png",
      "/images/We-screen3.png",
    ],
    url: "https://www.we-app.love",
  },
  {
    id: 'guesswho',
    title: "guess who",
    subtitle: ". famous traditional game",
    category: "webdesign",
    description:
      "Let’s play to this traditional game where you have to find a hidden character among 25. For that, you can choose different characteristics to sort them, & then have less and less choice. Depending on the level, you have X remaining chances to find it.",
    mainpicture: "/images/Code-Guesswho.jpg",
    picturesArray: [
      "/images/Guesswho-screen1.png",
      "/images/Guesswho-screen2.png",
      "/images/Guesswho-screen3.png",
      "/images/Guesswho-screen4.png",
      "/images/Guesswho-screen5.png",
    ],
    url: "http://laurastromboni.github.io/guesswho/",
  },
  {
    id: 'ovinocode',
    title: "o'vino",
    subtitle: ". sommelier à domicile",
    category: "webdesign",
    description:
      "Food and wine pairing delivery application, with advice & social services. Tell us what you eat, our sommelier will suggest you 3 different wines, and we deliver where & when you want.",
    mainpicture: "/images/Code-Ovino.jpg",
    picturesArray: [
      "/images/Ovino-screen1.gif",
      "/images/Ovino-screen2.png",
      "/images/Ovino-screen3.gif",
      "/images/Ovino-screen4.png",
      "/images/Ovino-screen5.gif",
      "/images/Ovino-screen6.gif",
    ],
    url: "http://ovino.herokuapp.com",
  },
  {
    id: 'airbnb',
    title: "airbnb",
    subtitle: ". the most realistic clone",
    category: "webdesign",
    description:
      "This Airbnb clone has developed all the house rental features as on the real website : search, google map, filters, calendar, bookings, messages, notifications... and 100% responsive.",
    mainpicture: "/images/Code-Airbnb.jpg",
    picturesArray: [
      "/images/Airbnb-screen1.png",
      "/images/Airbnb-screen2.png",
      "/images/Airbnb-screen3.png",
      "/images/Airbnb-screen4.png",
      "/images/Airbnb-screen5.png",
    ],
    url: "http://iron-airbnb.herokuapp.com",
  },
  {
    id: 'lacuisinedefelix',
    title: "la cuisine de félix",
    subtitle: ". gastronomic meal by home chef",
    category: "webdesign",
    description:
      "Felix Pollet has been a cook for over 10 years and now a chef at home. He offers a selection of gourmet dishes cooked with fresh products, all for exceptional meals.",
    mainpicture: "/images/Webdesign-Felix.jpg",
    picturesArray: [
      "/images/Felix-screen1.gif",
      "/images/Felix-screen2.gif",
      "/images/Felix-screen3.png",
      "/images/Felix-screen4.png",
      "/images/Felix-screen5.gif",
      "/images/Felix-screen6.png"
    ],
    url: "https://lacuisinedefelix.fr",
  },
  {
    id: 'balluta',
    title: "balluta",
    subtitle: ". vegan shoes brand",
    category: "webdesign",
    description:
      "Balluta is a footwear brand on a mission to create edgy vegan shoes for the modern woman. Their shoes are entirely designed and produced in Portugal, using traditional techniques and high-end eco-friendly materials which reflect our commitment with sustainability. Project of Gaspard+Bruno.",
    mainpicture: "/images/Webdesign-Balluta.jpg",
    picturesArray: [
      "/images/Balluta-screen1.png",
      "/images/Balluta-screen2.png",
      "/images/Balluta-screen3.png",
      "/images/Balluta-screen4.png",
      "/images/Balluta-screen5.png",
      "/images/Balluta-screen6.png",
      "/images/Balluta-screen7.gif",
    ],
    url: "https://balluta-shoes.com",
  },
  {
    id: 'nudedesign',
    title: "nude",
    subtitle: ". fashion store",
    category: "webdesign",
    description:
      "Nude Fashion Store is a womenswear multi-brand store based in Lisbon. With a bohemian aesthetic influence, we curate a unique brand selection for the modern woman, moving between bold statements and timeless classics. Project of Gaspard+Bruno.",
    mainpicture: "/images/Webdesign-Nude.jpg",
    picturesArray: [
      "/images/Nude-screen1.png",
      "/images/Nude-screen2.png",
      "/images/Nude-screen3.gif",
      "/images/Nude-screen4.gif",
      "/images/Nude-screen5.gif",
      "/images/Nude-screen6.gif",
    ],
    url: "http://nude.com.pt/en/",
  },
  {
    id: 'audiencerpdesign',
    title: "audience rp",
    subtitle: ". fashion public relations agency",
    category: "webdesign",
    description:
      "Contemporary PR agency specialized in fashion, design and lifestyle, reinventing communication strategies in the digital scene.",
    mainpicture: "/images/Webdesign-Audience.jpg",
    picturesArray: [
      "/images/Audience-screen0.gif",
      "/images/Audience-screen2.png",
      "/images/Audience-screen3.png",
      "/images/Audience-screen1.png",
      "/images/Audience-screen4.png",
      "/images/Audience-screen5.png",
      "/images/Audience-screen6.png",
      "/images/Audience-screen7.png",
    ],
    url: "https://www.behance.net/gallery/42609751/Audience",
  },
  {
    id: 'laederach',
    title: "laederach",
    subtitle: ". swiss chocolatier",
    category: "webdesign",
    description:
      "Läderach is a swiss chocolatier founded in 1962 and recognized all over the world. Project of Gaspard+Bruno.",
    mainpicture: "/images/Webdesign-Laederach.jpg",
    picturesArray: [
      "/images/Laederach-screen1.png",
      "/images/Laederach-screen2.png",
      "/images/Laederach-screen3.png",
      "/images/Laederach-screen4.png",
      "/images/Laederach-screen5.png",
      "/images/Laederach-screen6.png",
      "/images/Laederach-screen7.png",
    ],
    url: "https://www.laederach.com/en/",
  },
  {
    id: 'opal',
    title: "opal",
    subtitle: ". glasses designer & reseller",
    category: "webdesign",
    description:
      "Opal is a famous glasses designer and has a position as European leader in children’s and teenagers’ frames. Project of Gaspard+Bruno.",
    mainpicture: "/images/Webdesign-Opal.jpg",
    picturesArray: [
      "/images/Opal-screen1.gif",
      "/images/Opal-screen2.png",
      "/images/Opal-screen3.gif",
    ],
  },
  // BRANDING
  {
    id: 'roar',
    title: "roar",
    subtitle: ". for real animals lovers",
    category: "branding",
    description:
      "Roar is an ethical french brand selling fake fur clothes.",
    mainpicture: "/images/Branding-Roar.jpg",
    picturesArray: [
      "/images/Logo_Roar-mockup-2.jpg",
      "/images/Logo_Roar-mockup-1.jpg",
      "/images/Logo_Roar.png",
      "/images/Logo_Roar-image.jpg",
    ],
    url: "",
  },
  {
    id: 'lacave',
    title: "la cave des pas sages",
    subtitle: ".wine bar",
    category: "branding",
    description:
      "La Cave des Pas Sages is a french wine bar based in Avignon, France. It has a comedy club and concert nights.",
    mainpicture: "/images/Branding-Lacave.jpg",
    picturesArray: [
      "/images/Logo_Lacave-mockup.jpg",
      "/images/Logo_Lacave-image-2.jpg",
      "/images/Logo_Lacave-image-1.jpg",
      "/images/Logo-Lacave-0.png",
      "/images/Logo-Lacave-1.png",
      "/images/Logo-Lacave-2.png",
      "/images/Logo-Lacave-Flyer-1.jpg",
      "/images/Logo-Lacave-Flyer-2.jpg",
      "/images/Logo-Lacave-Flyer-3.jpg",
      "/images/Logo-Lacave-Flyer-4.jpg",
      "/images/Logo-Lacave-Flyer-5.jpg",
      "/images/Logo-Lacave-Flyer-6.jpg",
    ],
    url: "https://www.facebook.com/cavedes.passages",
  },
  {
    id: 'chico',
    title: "chico",
    subtitle: ". eco-friendly coffee",
    category: "branding",
    description:
      "Chico is an eco-friendly coffee brand.",
    mainpicture: "/images/Branding-Chico.jpg",
    picturesArray: [
      "/images/Branding-Chico.jpg",
      "/images/Logo_Chico-image-1.jpg",
      "/images/Logo_Chico-image-2.jpg",
      "/images/Logo_Chico-image-3.jpg",
    ],
    url: "",
  },
  {
    id: 'faustine',
    title: "faustine",
    subtitle: ". music singer",
    category: "branding",
    description:
      "Faustine is a french music singer.",
    mainpicture: "/images/Branding-Faustine.jpg",
    picturesArray: [
      "/images/Logo_Faustine-mockup-1.jpg",
      "/images/Logo_Faustine.png",
      "/images/Logo_Faustine-image.jpg",
    ],
    url: "https://www.faustineofficiel.fr",
  },
  {
    id: 'alixaudon',
    title: "alix audon",
    subtitle: ". interior designer",
    category: "branding",
    description:
      "Alix Audon is a french interior designer specialized in holistic approach.",
    mainpicture: "/images/Branding-Alix.jpg",
    picturesArray: [
      "/images/Logo_Alix-mockup.jpg",
      "/images/Logo_Alix-image.jpg",
      "/images/Logo_Alix.png",
      "/images/Logo_Alix-2.jpg",
    ],
    url: "https://www.alix-interieur.com",
  },
  {
    id: 'yo&co',
    title: "yo & co",
    subtitle: ". creation studio",
    category: "branding",
    description:
      "Yo & Co is a french creation studio that combines expertise, know-how and creativity in a modern and unique overall.",
    mainpicture: "/images/Branding-Yo.jpg",
    picturesArray: [
      "/images/Logo_Yo-mockup.jpg",
      "/images/Logo_Yo-image.jpg",
      "/images/Logo_Yo.png",
    ],
    url: "https://www.instagram.com/p/B86qYvcCldE/",
  },
  {
    id: 'baoqus',
    title: "baoqus",
    subtitle: ". bao & wine restaurant",
    category: "branding",
    description:
      "Baoqus is a mix between « Bao » (food) and « Bacchus » (wine God). This restaurant serves half gourmet food to go for bons vivants.",
    mainpicture: "/images/Branding-Baoqus.jpg",
    picturesArray: [
      "/images/Logo_Baoqus-mockup.jpg",
      "/images/Logo_Baoqus-image.jpg",
      "/images/Logo_Baoqus.png",
      "/images/Logo_Baoqus-2.jpg",
    ],
    url: "",
  },
  {
    id: 'mdvinsta',
    title: "my dear vagina",
    subtitle: ". body positive instagram account",
    category: "branding",
    description:
      "Manifesto of modern times, My Dear Vagina is a real declaration of love made to women by creative people who seek to show their beauty on paper. This call to difference, this hymn to nature has not any other purpose than to celebrate the female sex in all its splendor.",
    mainpicture: "/images/Branding-MDV.jpg",
    picturesArray: [
      "/images/Logo_MDV-mockup.jpg",
      "/images/Logo_MDV-image.jpg",
      "/images/Logo_MDV.png",
    ],
    url: "https://www.instagram.com/mydearvagina",
  },
  {
    id: 'ncd',
    title: "ncd",
    subtitle: ". product design",
    category: "branding",
    description:
      "NCD Wabibisa is a design and handmade furniture brand made in Marseille. In an elegant mix of wood and metal, NCD revisits the custom-made by revealing a modern way to show shapes and angles.",
    mainpicture: "/images/Branding-NCD.jpg",
    picturesArray: [
      "/images/Logo_NCD-mockup.jpg",
      "/images/Logo_NCD-image.jpg",
      "/images/Logo_NCD.png",
    ],
    url: "https://www.instagram.com/ncd.wabibisa/",
  },
  {
    id: 'webrand',
    title: "we",
    subtitle: ". mobile dating app",
    category: "branding",
    description:
      "We is a new dating app. Andy Warhol said once « People should fall in love with their eyes closed », We did it.",
    mainpicture: "/images/Branding-We.jpg",
    picturesArray: [
      "/images/Logo_We-mockup.jpg",
      "/images/Logo_We-image.jpg",
      "/images/Logo_We.png",
    ],
    url: "https://www.we-app.love",
  },
  {
    id: 'vertigobrand',
    title: "vertigo",
    subtitle: ". web serie about cinema",
    category: "branding",
    description:
      "Vertigo's ambition is to make (re) discover the cinematographic heritage through our small screens. Its DNA is to show the emotions or sensations that one feels when watching a movie, to make the viewer dizzy.",
    mainpicture: "/images/Branding-Vertigo.png",
    picturesArray: [
      "/images/Logo_Vertigo-mockup.jpg",
      "/images/Logo_Vertigo-image.jpg",
      "/images/Logo_Vertigo.png",
    ],
    url: "https://www.behance.net/gallery/69120833/Mmoire-projet-de-fin-dtudes",
  },
  {
    id: 'nudebrand',
    title: "nude",
    subtitle: ". fashion store",
    category: "branding",
    description:
      "Nude Fashion Store is a womenswear multi-brand store based in Lisbon. With a bohemian aesthetic influence, we curate a unique brand selection for the modern woman, moving between bold statements and timeless classics. Project of Gaspard+Bruno.",
    mainpicture: "/images/Branding-Nude.png",
    picturesArray: [
      "/images/Logo_Nude-mockup.jpg",
      "/images/Logo_Nude-image.jpg",
      "/images/Logo_Nude.png",
    ],
    url: "http://nude.com.pt/en/",
  },
  {
    id: 'mieldevasarely',
    title: "le miel de vasarely",
    subtitle: ". luxury honey made in the foundation",
    category: "branding",
    description:
      "Victor Vasarely has always had a fascination for alveolus, hexagons and France. It is found in the architecture of the architectonic center of the Vasarely Foundation but also in the choice of exhibited works. Since 2017, the foundation has decided to make its own honey and resell it on site to enhance this place now shelter bees.",
    mainpicture: "/images/Branding-Vasarely.png",
    picturesArray: [
      "/images/Logo_Vasarely-mockup.jpg",
      "/images/Logo_Vasarely-image.jpg",
      "/images/Logo_Vasarely.png",
    ],
  },
  {
    id: 'audiencerpbrand',
    title: "audience rp",
    subtitle: ". fashion public relations agency",
    category: "branding",
    description:
      "Contemporary PR agency specialized in fashion, design and lifestyle, reinventing communication strategies in the digital scene.",
    mainpicture: "/images/Branding-Audience.png",
    picturesArray: [
      "/images/Logo_Audience-mockup.jpg",
      "/images/Logo_Audience-image.jpg",
      "/images/Logo_Audience.gif",
    ],
    url: "https://www.behance.net/gallery/42609751/Audience",
  },
  {
    id: 'ovinobrand',
    title: "o'vino",
    subtitle: ". sommelier à domicile",
    category: "branding",
    description:
      "Food and wine pairing delivery application, with advice & social services. Tell us what you eat, our sommelier will suggest you 3 different wines, and we deliver where & when you want.",
    mainpicture: "/images/Branding-Ovino.png",
    picturesArray: [
      "/images/Logo_Ovino-mockup.jpg",
      "/images/Logo_Ovino-image.jpg",
      "/images/Logo_Ovino.png",
    ],
    url: "http://ovino.herokuapp.com",
  },
  // EDITION
  {
    id: 'desirbook',
    title: "petit traité du désir",
    subtitle: ". book",
    category: "edition",
    description:
      "The reference book about libido in couples. Sex, communication and kindness.",
    mainpicture: "/images/Mockup-Desir-1.jpg",
    picturesArray: [
      "/images/Mockup-Desir-2.jpg",
      "/images/Mockup-Desir-4.jpg",
      "/images/Mockup-Desir-5.jpg",
      "/images/Mockup-Desir-6.jpg",
      "/images/Mockup-Desir-7.jpg",
      "/images/Mockup-Desir-1.jpg",
      "/images/Mockup-Desir-3.jpg",
    ],
    url: "https://www.fnac.com/a20434144/Pierre-Dubol-Petit-traite-du-desir-Sexe-communication-et-bienveillance",
  },
  {
    id: 'mdvbook',
    title: "my dear vagina",
    subtitle: ". ig account & book",
    category: "edition",
    description:
      "Manifesto of modern times, My Dear Vagina is a real declaration of love made to women by creative people who seek to show their beauty on paper. This call to difference, this hymn to nature has not any other purpose than to celebrate the female sex in all its splendor.",
    mainpicture: "/images/Book-MDV-1.jpg",
    picturesArray: [
      "/images/Book-MDV-2.jpg",
      "/images/Book-MDV-3.jpg",
      "/images/Book-MDV-4.jpg",
      "/images/Book-MDV-5.jpg",
      "/images/Book-MDV-6.jpg",
      "/images/Book-MDV.jpg",
    ],
    url: "https://tidd.ly/3jF4b3c",
  },
  {
    id: 'pathologies',
    title: "the illustrated guide to vulvar pathologies",
    subtitle: ". vulvae guide",
    category: "edition",
    description:
      "This guide is full of information on vulvar pathologies, which are too little known and still poorly diagnosed. Yet they do exist, and the good new is that most of them can be cured!",
    mainpicture: "/images/Guide-pathologies.jpg",
    picturesArray: [
      "/images/Guide-pathologies-2.jpg",
      "/images/Guide-pathologies-3.jpg",
      "/images/Guide-pathologies-4.jpg"
    ],
    url: "https://www.vulvae.io/guideillustre-douleurs-vulvaires",
  },
  {
    id: 'vertigoillu',
    title: "vertigo",
    subtitle: ". web serie about cinema",
    category: "edition",
    description:
      "Vertigo's ambition is to make (re) discover the cinematographic heritage through our small screens. Its DNA is to show the emotions or sensations that one feels when watching a movie, to make the viewer dizzy.",
    mainpicture: "/images/Illustration1-3.jpg",
    picturesArray: [
      "/images/Illustration1-4.jpg",
      "/images/Illustration1-3.jpg",
    ],
    url: "https://www.behance.net/gallery/69120833/Mmoire-projet-de-fin-dtudes",
  },
  // ILLUSTRATION
  // {
  //   id: 'mdvprint',
  //   title: "feminist frames",
  //   subtitle: ". original prints",
  //   category: "illustration",
  //   description:
  //     "This serie of 6 stickers made for @mydearvagina celebrates women strength in a modern way.",
  //   mainpicture: "/images/Print-MDV.jpg",
  //   picturesArray: [
  //     "/images/MDV-print-3.jpg",
  //     "/images/MDV-print-2.jpg",
  //     "/images/MDV-print-1.jpg",
  //     "/images/MDV-print-4.jpg",
  //   ],
  //   url: "https://www.etsy.com/fr/shop/mydearvagina",
  // },
  {
    id: 'lovecollection',
    title: "love",
    subtitle: ". digital painting",
    category: "illustration",
    description:
      "Love serie, drawings made from photos by @sarahsalazar.pics, with as models @romain.mc and @caldara_who & others.",
    mainpicture: "/images/Drawing-love.jpg",
    picturesArray: [
      "/images/Drawing-love-1.jpg",
      "/images/Drawing-love-2.jpg",
      "/images/Drawing-love-3.jpg",
      "/images/Drawing-love-4.jpg",
      "/images/Drawing-love-5.png",
      "/images/Drawing-love-6.png"
    ],
  },
  {
    id: 'kamasutracollection',
    title: "kamasutra game",
    subtitle: ". digital painting",
    category: "illustration",
    description:
      "Kamasutra modern cards game of 52 positions, editing by Editions 365.",
    mainpicture: "/images/Drawing-kamasutragame.jpg",
    picturesArray: [
      "/images/Kamasutra-couv-2.jpg",
      "/images/Kamasutra-1.jpg",
      "/images/Kamasutra-2.jpg",
      "/images/Kamasutra-3.jpg",
      "/images/Kamasutra-4.jpg",
      "/images/Kamasutra-couv.jpg"
    ],
    url: "https://livre.fnac.com/a17480160/Laura-Stromboni-Kamasutra-52-cartes-pour-s-oc-cul-per",
  },
  {
    id: 'desircollection',
    title: "desire",
    subtitle: ". digital painting",
    category: "illustration",
    description:
      "Desire serie.",
    mainpicture: "/images/Drawing-desir.jpg",
    picturesArray: [
      "/images/Desir-1.jpg",
      "/images/Desir-2.jpg",
      "/images/Desir-3.jpg",
      "/images/Desir-4.jpg",
      "/images/Desir-5.jpg",
      "/images/Desir-6.png",
      "/images/Desir-7.png",
      "/images/Desir-8.png",
      "/images/Desir-9.png",
      "/images/Desir-10.png",
      "/images/Desir-11.png",
      "/images/Desir-12.png",
      "/images/Desir-13.png",
      "/images/Desir-14.png",
      "/images/Desir-15.png",
      "/images/Desir-16.png",
      "/images/Desir-17.png",
      "/images/Desir-18.png",
      "/images/Desir-19.png",
      "/images/Desir-20.png",
      "/images/Desir-21.png",
      "/images/Desir-22.png",
      "/images/Desir-23.png",
      "/images/Desir-24.png",
      "/images/Desir-25.png",
      "/images/Desir-26.png",
      "/images/Desir-27.png",
      "/images/Desir-28.png",
      "/images/Desir-29.png",
      "/images/Desir-30.png",
      "/images/Desir-31.png",
      "/images/Desir-32.png",
      "/images/Desir-33.png",
      "/images/Desir-34.png",
      "/images/Desir-35.png",
      "/images/Desir-36.png",
      "/images/Desir-37.png",
      "/images/Desir-38.png",
      "/images/Desir-39.png",
      "/images/Desir-40.png",
      "/images/Desir-41.png",
      "/images/Desir-42.png",
      "/images/Desir-43.png",
      "/images/Desir-44.png",
      "/images/Desir-45.png",
      "/images/Desir-46.png",
    ],
  },
  {
    id: 'fantasmescollection',
    title: "fantasies",
    subtitle: ". digital painting",
    category: "illustration",
    description:
      "French fantasies illustrations serie.",
    mainpicture: "/images/Drawing-fantasmes.jpg",
    picturesArray: [
      "/images/Fantasmes-1.jpg",
      "/images/Fantasmes-2.jpg",
      "/images/Fantasmes-3.jpg",
      "/images/Fantasmes-4.jpg",
      "/images/Fantasmes-5.jpg"
    ],
    url: "https://livre.fnac.com/a17480159/Anne-Marie-Menard-Sex-talk-52-cartes-pour-se-re-decouvrir",
  },
  {
    id: 'periodscollection',
    title: "periods",
    subtitle: ". digital painting",
    category: "illustration",
    description:
      "Just a serie of periods illustrations.",
    mainpicture: "/images/Drawing-periods.jpg",
    picturesArray: [
      "/images/Les_Regles_1.png",
      "/images/Les_Regles_2.png",
      "/images/Les_Regles_3.png",
      "/images/Les_Regles_4.png",
      "/images/Les_Regles_5.png",
      "/images/Les_Regles_6.png",
      "/images/Les_Regles_7.png",
      "/images/Les_Regles_8.png",
      "/images/Les_Regles_9.png",
      "/images/Les_Regles_10.png",
      "/images/Les_Regles_11.png",
      "/images/Les_Regles_12.png",
      "/images/Les_Regles_13.png",
      "/images/Les_Regles_14.png",
      "/images/Les_Regles_15.png",
    ],
    url: "",
  },
  {
    id: 'couplegamecollection',
    title: "sex talk",
    subtitle: ". digital painting",
    category: "illustration",
    description:
      "Intimate couple cards game, editing by Editions 365.",
    mainpicture: "/images/Drawing-couplegame.jpg",
    picturesArray: [
      "/images/Sextalk-couv.jpg",
      "/images/Picto_1.jpg",
      "/images/Picto_2.jpg",
      "/images/Picto_3.jpg",
      "/images/Picto_4.jpg",
      "/images/Picto_5.jpg",
      "/images/Picto_6.jpg",
      "/images/Picto_7.jpg",
      "/images/Picto_8.jpg",
      "/images/Picto_9.jpg",
      "/images/Picto_10.jpg"
    ],
    url: "https://livre.fnac.com/a17480159/Anne-Marie-Menard-Sex-talk-52-cartes-pour-se-re-decouvrir",
  },
  {
    id: 'sextoyscollection',
    title: "pleasure objects",
    subtitle: ". digital painting",
    category: "illustration",
    description:
      "Sextoys collection studies.",
    mainpicture: "/images/Drawing-sextoy.jpg",
    picturesArray: [
      "/images/Drawing-sextoy-4.jpg",
      "/images/Drawing-sextoy-6.jpg",
      "/images/Drawing-sextoy-7.jpg",
      "/images/Drawing-sextoy-5.jpg",
      "/images/Drawing-sextoy-1.jpg",
      "/images/Drawing-sextoy-2.jpg",
      "/images/Drawing-sextoy-3.jpg"
    ],
  },
  {
    id: 'self love',
    title: "intimate portraits",
    subtitle: ". digital painting",
    category: "illustration",
    description:
      "Self love & intimate everyday scenes study.",
    mainpicture: "/images/Drawing-selflove.jpg",
    picturesArray: [
      "/images/Drawing-BDSM.png",
      "/images/Drawing-selflove-1.jpg",
      "/images/Drawing-selflove-2.jpg",
    ],
  },
  {
    id: 'women poetry',
    title: "bodies study",
    subtitle: ". digital painting",
    category: "illustration",
    description:
      "Just about poetry and women.",
    mainpicture: "/images/Drawing-women-0.jpg",
    picturesArray: [
      "/images/Drawing-women-1.jpg",
      "/images/Drawing-women-2.jpg",
    ],
  },
  {
    id: 'musician',
    title: "musician illustration",
    subtitle: ". digital painting",
    category: "illustration",
    description:
      "Digital painting of a drummer.",
    mainpicture: "/images/Drawing-drummer.jpg",
    picturesArray: [
      "/images/Drawing-drummer.jpg",
    ],
  },
  {
    id: 'boysillu',
    title: "boys illustrations",
    subtitle: ". digital painting",
    category: "illustration",
    description:
      "This serie of 3 illustrations - Léo Walk, Xavier Dolan, Lomepal - has been made with a graphic tablet.",
    mainpicture: "/images/illustration-portraits.jpg",
    picturesArray: [
      "/images/illustration-leo.jpg",
      "/images/illustration-xavier.jpg",
      "/images/illustration-lomepal.jpg",
    ],
  },
  {
    id: 'femillu',
    title: "feminist stickers",
    subtitle: ". original illustrations",
    category: "illustration",
    description:
      "This serie of stickers made for @mydearvagina celebrates women strength in a modern way.",
    mainpicture: "/images/illustration-stickers.png",
    picturesArray: [
      "/images/Illustration4-12.jpg",
      "/images/Illustration4-13.jpg",
      "/images/Illustration4-14.jpg",
      "/images/Illustration4-15.jpg",
      "/images/Illustration4-7.jpg",
      "/images/Illustration4-9.jpg",
      "/images/Illustration4-10.jpg",
      "/images/Illustration4.jpg",
      "/images/Illustration4-2.jpg",
      "/images/Illustration4-4.jpg",
      "/images/Illustration4-5.jpg",
      "/images/Illustration4-6.jpg",
      "/images/Illustration4-8.jpg",
      "/images/Illustration4-11.jpg",
      "/images/Illustration4-3.jpg",
    ],
  },
  {
    id: 'pinkoctoberdrawings',
    title: "pink october",
    subtitle: ". digital painting",
    category: "illustration",
    description:
      "Just a pink october drawings collection.",
    mainpicture: "/images/Drawing-pinkoctober.jpg",
    picturesArray: [
      "/images/Pinkoctober.jpg",
      "/images/Pinkoctober-0.jpg",
      "/images/Pinkoctober-1.jpg",
      "/images/Pinkoctober-2.jpg",
      "/images/Pinkoctober-3.jpg",
      "/images/Pinkoctober-4.jpg",
      "/images/Pinkoctober-5.jpg",
      "/images/Pinkoctober-6.jpg",
      "/images/Pinkoctober-7.jpg",
    ],
  },
  {
    id: 'womenfaces',
    title: "women faces",
    subtitle: ". original embroideried portraits",
    category: "illustration",
    description:
      "This series of 3 female portraits pays tribute to the great feminist figures of yesterday and today, and highlights them with a realistic drawing with hand-embroidered details.",
    mainpicture: "/images/illustration-embroidery.png",
    picturesArray: [
      "/images/Illustration2.jpg",
      "/images/Illustration2-2.jpg",
      "/images/Illustration2-3.jpg",
    ],
  },
  {
    id: 'watercolor',
    title: "watercolor moving",
    subtitle: ". animated drawings",
    category: "illustration",
    description:
      "These drawings were done in watercolor before being digitally animated. They represent random people portraits.",
    mainpicture: "/images/illustration-watercolors.png",
    picturesArray: ["/images/Illustration3-2.gif",
      "/images/Illustration3.gif"
    ],
  },
  {
    id: 'anatomydrawings',
    title: "anatomy drawings",
    subtitle: ". digital painting",
    category: "illustration",
    description:
      "Just a collection of anatomy drawings.",
    mainpicture: "/images/Drawing-anatomy.jpg",
    picturesArray: [
      "/images/Drawing-random-2.jpg",
      "/images/Drawing-anatomy-1.jpg",
      "/images/Drawing-anatomy-2.jpg",
      "/images/Drawing-anatomy-3.jpg",
      "/images/Drawing-anatomy-4.jpg",
      "/images/Drawing-anatomy-5.jpg",
      "/images/Drawing-random-1.jpg",
      "/images/Drawing-venus.png",
    ],
  },
  // TATTOO 
  {
    id: 'flashicons',
    title: "icons",
    subtitle: ". flash tattoos",
    category: "tattoo",
    description:
      "Just a serie of iconics portraits flash tattoos.",
    mainpicture: "/images/Drawing-flash-1.jpg",
    picturesArray: [
      "/images/Flash-Cinema.jpg",
      "/images/Flash-Frida.jpg",
      "/images/Flash-Amy.jpg",
      "/images/Flash-Josephine.jpg",
      "/images/Flash-Nina.jpg",
      "/images/Flash-Mia.jpg",
      "/images/Flash-Bebel.jpg",
      "/images/Flash-ACDC.jpg",
      "/images/Flash-Walkance.jpg"
    ],
    url: "https://www.instagram.com/culottee.ttt",
  },  
  {
    id: 'flashanimals',
    title: "animals",
    subtitle: ". flash tattoos",
    category: "tattoo",
    description:
      "Just a serie of animals flash tattoos.",
    mainpicture: "/images/Drawing-flash-2.jpg",
    picturesArray: [
      "/images/Flash-Felins-2.jpg",
      "/images/Flash-Flowers-3.jpg",
      "/images/Flash-Oiseaux.jpg",
      "/images/Flash-Tigre.jpg",
      "/images/Flash-Amour.jpg",
      "/images/Flash-Cat-2.jpg",
      "/images/Flash-Marine.jpg",
      "/images/Flash-Lovelycat.jpg",
      "/images/Flash-Marin.jpg",
      "/images/Flash-Reptiles.jpg",
      "/images/Flash-Ocean.jpg",
      "/images/Flash-Dog.jpg",
      "/images/Flash-Insectes.jpg",
      "/images/Flash-Butterfly.jpg",
      "/images/Flash-Cat.jpg",
      "/images/Flash-Felins.jpg",
      "/images/Flash-Animauxamoureux.jpg",
      "/images/Flash-Cetaces.jpg",
      "/images/Flash-Plumes.jpg",
      "/images/Flash-Animal.jpg",
      "/images/Flash-Poissons.jpg"
    ],
    url: "https://www.instagram.com/culottee.ttt",
  },  
  {
    id: 'flashbodies',
    title: "bodies",
    subtitle: ". flash tattoos",
    category: "tattoo",
    description:
      "Just a serie of bodies flash tattoos.",
    mainpicture: "/images/Drawing-flash-3.jpg",
    picturesArray: [
      "/images/Flash-Baisers-2.jpg",
      "/images/Flash-Love.jpg",
      "/images/Flash-Corps.jpg",
      "/images/Flash-Enlacer.jpg",
      "/images/Flash-FemmesFleurs.jpg",
      "/images/Flash-Butt.jpg",
      "/images/Flash-Boobs.jpg",
      "/images/Flash-Skull.jpg",
      "/images/Flash-Heartbody.jpg",
      "/images/Flash-Ete.jpg",
      "/images/Flash-Touche.jpg",
      "/images/Flash-Baisers.jpg",
      "/images/Flash-Resilles.jpg",
      "/images/Flash-Tendresse.jpg",
      "/images/Flash-Culs.jpg",
      "/images/Flash-Sexy.jpg",
      "/images/Flash-Vulvae.jpg",
      "/images/Flash-Anatomie.jpg",
      "/images/Flash-Corps-2.jpg",
      "/images/Flash-Oeil.jpg",
      "/images/Flash-Vulvae-2.jpg",
      "/images/Flash-Faces.jpg"
    ],
    url: "https://www.instagram.com/culottee.ttt",
  },  
  {
    id: 'flashlyrics',
    title: "lyrics",
    subtitle: ". flash tattoos",
    category: "tattoo",
    description:
      "Just a serie of lyrics flash tattoos.",
    mainpicture: "/images/Drawing-flash-9.jpg",
    picturesArray: [
      "/images/Flash-Lyrics-11.jpg",
      "/images/Flash-Lyrics-12.jpg",
      "/images/Flash-Lyrics-10.jpg",
      "/images/Flash-Lyrics-2.jpg",
      "/images/Flash-Lyrics-1.jpg",
      "/images/Flash-Lyrics-3.jpg",
      "/images/Flash-Lyrics-4.jpg",
      "/images/Flash-Lyrics-5.jpg", 
      "/images/Flash-Lyrics-6.jpg",
      "/images/Flash-Lyrics-7.jpg", 
      "/images/Flash-Lyrics-8.jpg",
      "/images/Flash-Lyrics-9.jpg"
    ],
    url: "https://www.instagram.com/culottee.ttt",
  }, 
  {
    id: 'flashmovies',
    title: "movies",
    subtitle: ". flash tattoos",
    category: "tattoo",
    description:
      "Just a serie of movies flash tattoos.",
    mainpicture: "/images/Drawing-flash-11.jpg",
    picturesArray: [
      "/images/Flash-Movies-1.jpg"
    ],
    url: "https://www.instagram.com/culottee.ttt",
  }, 
  {
    id: 'flashobjects',
    title: "objects",
    subtitle: ". flash tattoos",
    category: "tattoo",
    description:
      "Just a serie of objects flash tattoos.",
    mainpicture: "/images/Drawing-flash-10.jpg",
    picturesArray: [
      "/images/Flash-Allumette-2.jpg",
      "/images/Flash-Bateaux.jpg",
      "/images/Flash-Allumette.jpg",
      "/images/Flash-Feu.jpg",
      "/images/Flash-Music.jpg",
      "/images/Flash-Echecs.jpg",
      "/images/Flash-Vin.jpg",
      "/images/Flash-Drums.jpg",
      "/images/Flash-Baguettes.jpg",
      "/images/Flash-Photography.jpg",
      "/images/Flash-Pellicules.jpg",
      "/images/Flash-Oeildeprotection.jpg"
    ],
    url: "https://www.instagram.com/culottee.ttt",
  }, 
  {
    id: 'flashartists',
    title: "artists",
    subtitle: ". flash tattoos",
    category: "tattoo",
    description:
      "Just a serie of artists flash tattoos.",
    mainpicture: "/images/Drawing-flash-7.jpg",
    picturesArray: [
      "/images/Flash-Keith.jpg",
      "/images/Flash-Matisse.jpg",
      "/images/Flash-Dali.jpg",
      "/images/Flash-Vangogh.jpg",
      "/images/Flash-Magritte.jpg",
      "/images/Flash-Klimt.jpg",
      "/images/Flash-Basquiat.jpg"
    ],
    url: "https://www.instagram.com/culottee.ttt",
  },
  {
    id: 'flashtypo',
    title: "typography",
    subtitle: ". flash tattoos",
    category: "tattoo",
    description:
      "Just a serie of font flash tattoos.",
    mainpicture: "/images/Drawing-flash-16.jpg",
    picturesArray: [
      "/images/Flash-Feminist.jpg",
      "/images/Flash-Poemes.jpg",
      "/images/Flash-Mots.jpg",
      "/images/Flash-Astro.jpg",
      "/images/Flash-Tendresse-2.jpg",
      "/images/Flash-Benisidore.jpg",
    ],
    url: "https://www.instagram.com/culottee.ttt",
  },
  {
    id: 'flashbadass',
    title: "badass",
    subtitle: ". flash tattoos",
    category: "tattoo",
    description:
      "Just a serie of badass flash tattoos.",
    mainpicture: "/images/Drawing-flash-4.jpg",
    picturesArray: [
      "/images/Flash-Feu-2.jpg",
      "/images/Flash-Badass.jpg",
    ],
    url: "https://www.instagram.com/culottee.ttt",
  },
  {
    id: 'flashexpressions',
    title: "expression",
    subtitle: ". flash tattoos",
    category: "tattoo",
    description:
      "Just a serie of expressions flash tattoos.",
    mainpicture: "/images/Drawing-flash-13.jpg",
    picturesArray: [
      "/images/Flash-Expressions-1.jpg",
      "/images/Flash-Expressions-2.jpg",
      "/images/Flash-Marseille.jpg"
    ],
    url: "https://www.instagram.com/culottee.ttt",
  },
  {
    id: 'flashfood',
    title: "food",
    subtitle: ". flash tattoos",
    category: "tattoo",
    description:
      "Just a serie of food flash tattoos.",
    mainpicture: "/images/Drawing-flash-14.jpg",
    picturesArray: [
      "/images/Flash-Piments.jpg",
      "/images/Flash-Asie.jpg",
      "/images/Flash-Sushi.jpg"
    ],
    url: "https://www.instagram.com/culottee.ttt",
  },
  {
    id: 'flashplaces',
    title: "places",
    subtitle: ". flash tattoos",
    category: "tattoo",
    description:
      "Just a serie of places flash tattoos.",
    mainpicture: "/images/Drawing-flash-15.jpg",
    picturesArray: [
      "/images/Flash-Lascaux.jpg",
      "/images/Flash-Egypte.jpg"
    ],
    url: "https://www.instagram.com/culottee.ttt",
  },
  {
    id: 'flashnature',
    title: "nature",
    subtitle: ". flash tattoos",
    category: "tattoo",
    description:
      "Just a serie of nature elements flash tattoos.",
    mainpicture: "/images/Drawing-flash-8.jpg",
    picturesArray: [
      "/images/Flash-Ete-2.jpg",
      "/images/Flash-Flowers-2.jpg",
      "/images/Flash-Plantes.jpg",
      "/images/Flash-Olivier.jpg",
      "/images/Flash-Fleurs-1.jpg",
      "/images/Flash-Fleurs-2.jpg",
      "/images/Flash-Fleurs-3.jpg",
      "/images/Flash-Flowers.jpg",
      "/images/Flash-Cactus.jpg",
      "/images/Flash-Constellations.jpg",
      "/images/Flash-Champignons.jpg"
    ],
    url: "https://www.instagram.com/culottee.ttt",
  }, 
  {
    id: 'flashchildhood',
    title: "childhood",
    subtitle: ". flash tattoos",
    category: "tattoo",
    description:
      "Just a serie of childhood flash tattoos.",
    mainpicture: "/images/Drawing-flash-12.jpg",
    picturesArray: [
      "/images/Flash-Petitprince.jpg",
      "/images/Flash-Pierrot.jpg",
      "/images/Flash-Bob.jpg"
    ],
    url: "https://www.instagram.com/culottee.ttt",
  },
  {
    id: 'flashmatching',
    title: "matching",
    subtitle: ". flash tattoos",
    category: "tattoo",
    description:
      "Just a serie of matching flash tattoos.",
    mainpicture: "/images/Drawing-flash-5.jpg",
    picturesArray: [
      "/images/Flash-Matching.jpg",
      "/images/Flash-Matching-2.jpg"
    ],
    url: "https://www.instagram.com/culottee.ttt",
  },  
  {
    id: 'flashbaby',
    title: "baby",
    subtitle: ". flash tattoos",
    category: "tattoo",
    description:
      "Just a serie of baby flash tattoos.",
    mainpicture: "/images/Drawing-flash-6.jpg",
    picturesArray: [
      "/images/Flash-Baby.jpg",
    ],
    url: "https://www.instagram.com/culottee.ttt",
  },  
  {
    id: 'flashmagic',
    title: "magic",
    subtitle: ". flash tattoos",
    category: "tattoo",
    description:
      "Just a serie of magic flash tattoos.",
    mainpicture: "/images/Drawing-flash-17.jpg",
    picturesArray: [
      "/images/Flash-Angels.jpg"
    ],
    url: "https://www.instagram.com/culottee.ttt",
  },  
  {
    id: 'flashheart',
    title: "heart",
    subtitle: ". flash tattoos",
    category: "tattoo",
    description:
      "Just a serie of heart flash tattoos.",
    mainpicture: "/images/Drawing-flash-18.jpg",
    picturesArray: [
      "/images/Flash-Coeurs.jpg",
      "/images/Flash-Coeurs-2.jpg",
      "/images/Flash-Coeurfeu.jpg",
      "/images/Flash-Petitscoeurs.jpg"
    ],
    url: "https://www.instagram.com/culottee.ttt",
  }, 
  // PRINT
  {
    id: 'roarposter',
    title: "roar",
    subtitle: ". for real animals lovers",
    category: "print",
    description:
      "Roar is an ethical french brand selling fake fur clothes.",
    mainpicture: "/images/Roar-posters.jpg",
    picturesArray: [
      "/images/Roar-poster-1.jpg",
      "/images/Roar-poster-2.jpg",
      "/images/Roar-poster-3.jpg",
      "/images/Roar-posters.jpg",
    ],
    url: "",
  },
  {
    id: 'bbelaych',
    title: "benjamin belaych",
    subtitle: ". french drummer",
    category: "print",
    description:
      "Benjamin Belaych is a french drummer who’s playing in several bands around the country.",
    mainpicture: "/images/Print-Belaych-1.jpg",
    picturesArray: [
      "/images/Print-Belaych-2.jpg",
      "/images/Print-Belaych-3.jpg",
    ],
    url: "https://www.instagram.com/benjaminbelaych",
  },
  {
    id: 'jones',
    title: "jones",
    subtitle: ". french bistrot posters",
    category: "print",
    description:
      "Serie of posters for a french food restaurant (bistrot) based in Paris.",
    mainpicture: "/images/Print-Jones2.png",
    picturesArray: [
      "/images/Print-Jones3.png",
      "/images/Print-Jones2.png",
      "/images/Print-Jones.jpg",
      "/images/Print-Jones4.png",
      "/images/Print-Jones5.png",
      "/images/Print-Jones6.png",
    ],
  },
  {
    id: 'notox',
    title: "notox",
    subtitle: ". surfboards posters",
    category: "print",
    description:
      "Serie of posters for ecofriendly surfboards brand based in Anglet.",
    mainpicture: "/images/Print-Notox.png",
    picturesArray: ["/images/Print-Notox2.png", "/images/Print-Notox.png"],
  },
  {
    id: 'vertigoprint',
    title: "vertigo",
    subtitle: ". web serie about cinema",
    category: "print",
    description:
      "Vertigo's ambition is to make (re) discover the cinematographic heritage through our small screens. Its DNA is to show the emotions or sensations that one feels when watching a movie, to make the viewer dizzy.",
    mainpicture: "/images/Print-Vertigo.jpg",
    picturesArray: ["/images/Print-Vertigo2.jpg", "/images/Print-Vertigo.jpg"],
    url: "https://www.behance.net/gallery/69120833/Mmoire-projet-de-fin-dtudes",
  },
  {
    id: 'leonprint',
    title: "leon",
    subtitle: ". theatre poster",
    category: "print",
    description:
      "Poster of an event of La Grande Cuisine du Petit Léon, a french play for children directed by Marc Wolters.",
    mainpicture: "/images/Poster-Leon.jpg",
    picturesArray: [
      "/images/Leon-poster-1.jpg",
      "/images/Leon-poster-2.jpg"
    ],
  },
  // ART DIRECTION
  {
    id: 'lmr',
    title: "hocus pocus",
    subtitle: ". @MyDearVagina x @Le_Mot_Rose",
    category: "artdirection",
    description:
      "Hocus Pocus is a collection of 3 hand-made brooches, « Faisan Doré », « Flamant Rose », « Phœnix », which are celebrating women’s beauty and freedom. 📷 DA & photography : Laura Stromboni - IG @mydearvagina / 💎 Embroidery : Camille Cireddu - IG @le_mot_rose",
    mainpicture: "/images/ArtDir-LMR.jpg",
    picturesArray: [
      "/images/LMR-1.jpg",
      "/images/LMR-2.jpg",
      "/images/LMR-3.jpg",
      "/images/LMR-4.jpg",
      "/images/LMR-5.jpg",
      "/images/LMR-6.jpg",
      "/images/LMR-7.jpg",
      "/images/LMR-8.jpg",
      "/images/LMR-9.jpg",
      "/images/LMR-10.jpg",
      "/images/LMR-11.jpg",
    ],
    url: "https://www.etsy.com/fr/shop/Lemotrose",
  },
  {
    id: 'vivalavulva',
    title: "viva la vulva",
    subtitle: ". exhibition",
    category: "artdirection",
    description:
      "Viva la vulva is an exhibition organized by Nana France and my brand My Dear Vagina. The aim is to break taboos about the female sex and thus to make women's speech free.",
    mainpicture: "/images/Vivalavulva-0.jpg",
    picturesArray: [
      "/images/Vivalavulva-1.jpg",
      "/images/Vivalavulva-2.jpg",
      "/images/Vivalavulva-3.jpg",
      "/images/Vivalavulva-4.jpg",
      "/images/Vivalavulva-5.jpg",
    ],
    url:
      "http://www.leparisien.fr/paris-75/paris-une-exposition-sur-la-vulve-sans-la-montrer-20-09-2019-8156433.php",
  },
  {
    id: 'mdv',
    title: "my dear vagina",
    subtitle: ". instagram account",
    category: "artdirection",
    description:
      "Working since March 2018 on this project to celebrate the diversity and the beauty of human's body.",
    mainpicture: "/images/ArtDir-MDV.jpg",
    picturesArray: [
      "/images/MDV-IG.png"
  ],
    url:
      "https://www.instagram.com/mydearvagina/",
  },
  {
    id: '8mars2020',
    title: "8 mars 2020",
    subtitle: ". @MyDearVagina x @CordesSensibles",
    category: "artdirection",
    description:
      "For the international women's rights day, I've worked with the talented Chloé Kobuta on an amazing sound collage. 📷 DA & photography : Laura Stromboni - IG @mydearvagina / 🎙 Sound collage : Chloé Kobuta - IG @cordessensibles ",
    mainpicture: "/images/mdvxcordessensibles.jpg",
    video: ["/images/mdvxcordessensibles.mp4"],
    poster: "/images/mdvxcordessensibles.jpg",
    url:
      "https://www.instagram.com/tv/B9fK--JhkuJ/?utm_source=ig_web_copy_link",
  },
  {
    id: 'justacup',
    title: "just a cup",
    subtitle: ". menstrual cup brand",
    category: "artdirection",
    description:
      "Just a Cup is a committed brand that wants to give every woman access to the hygienic conditions that they deserve. It makes women grow at the same time as society transmit sincere values, a preserved environment, a support towards education and health protection.",
    mainpicture: "/images/Justacup-0.jpg",
    picturesArray: [
      "/images/Justacup-1.jpg",
      "/images/Justacup-2.jpg",
      "/images/Justacup-3.jpg",
      "/images/Justacup-4.jpg",
      "/images/Justacup-5.jpg",
      "/images/Justacup-6.jpg",
      "/images/Justacup-7.jpg",
      "/images/Justacup-8.jpg",
    ],
    url: "https://just-a-cup.com/fr/",
  },
  {
    id: 'vertigoart',
    title: "vertigo",
    subtitle: ". web serie about cinema",
    category: "artdirection",
    description:
      "Vertigo's ambition is to make (re) discover the cinematographic heritage through our small screens. Its DNA is to show the emotions or sensations that one feels when watching a movie, to make the viewer dizzy.",
    mainpicture: "/images/ArtDir-Vertigo.png",
    video: ["/images/Vertigo-video.mp4"],
    poster: "/images/poster-vertigo.png",
    url: "https://www.youtube.com/watch?v=0YG5FVfgkQ4&t=3s",
  },
  {
    id: 'audiencerpart',
    title: "audience rp",
    subtitle: ". fashion public relations agency",
    category: "artdirection",
    description:
      "Contemporary PR agency specialized in fashion, design and lifestyle, reinventing communication strategies in the digital scene.",
    mainpicture: "/images/ArtDir-Audience.jpg",
    video: ["/images/Audience-video.mp4", "/images/Audience-video2.mp4"],
    poster: "/images/poster-audience.png",
    url: "https://www.youtube.com/watch?v=--DckdhpNeA",
  },
  {
    id: 'motion+',
    title: "motion +",
    subtitle: ". motion festival",
    category: "artdirection",
    description:
      "Since 2013, Motion + regularly showcased videos and favorites, as well as an annual selection to participate to the festival which takes place at the end of the year in Aix-en-Provence, South of France.",
    mainpicture: "/images/ArtDir-Motion.jpg",
    video: ["/images/Motionplus-video.mp4"],
    poster: "/images/poster-motion.png",
    url: "https://www.youtube.com/watch?v=64KPSEjf6Cg",
  },
  {
    id: 'gallimard',
    title: "gallimard",
    subtitle: ". fantastic animals collection",
    category: "artdirection",
    description:
      "The Gallimard editions wants to promote their new collection 'Fantastic Animals', intended for young and old. The goal is to make you want to see more and discover it.",
    mainpicture: "/images/ArtDir-Animaux.jpg",
    video: ["/images/Gallimard-video.mp4"],
    poster: "/images/poster-gallimard.png",
    url: "https://www.youtube.com/watch?v=i-qdlFv6YQo",
  },
  {
    id: 'covers',
    title: "album cover",
    subtitle: ". music",
    category: "artdirection",
    description:
      "Here are some album covers I created of music bands.",
    mainpicture: "/images/ArtDir-UTDO.jpg",
    appArray: [
      "/images/UTDO.png",
      "/images/Audrey.jpg",
    ],
  },
  // APP
  {
    id: 'weapp',
    title: "we",
    subtitle: ". mobile dating app",
    category: "app",
    description:
      "We is a new dating app. Andy Warhol said once « People should fall in love with their eyes closed », We did it.",
    mainpicture: "/images/App-We.jpg",
    appArray: [
      "/images/We-1.png",
      "/images/We-2.png",
      "/images/We-3.png",
      "/images/We-4.png",
      "/images/We-5.png",
      "/images/We-6.png",
      "/images/We-7.png",
    ],
  },
  {
    id: 'vianablue',
    title: "viana blue",
    subtitle: ". designer jewelry reseller",
    category: "app",
    description:
      "Viana Blue is a contemporary eshop which magnifies Portuguese creators luxury jewelry. Project of Gaspard+Bruno.",
    mainpicture: "/images/App-VianaBlue.jpg",
    appArray: [
      "/images/VB-1.png",
      "/images/VB-2.png",
      "/images/VB-3.png",
      "/images/VB-4.png",
      "/images/VB-5.png",
      "/images/VB-6.png",
      "/images/VB-7.png",
      "/images/VB-8.png",
      "/images/VB-9.png",
      "/images/VB-10.png",
      "/images/VB-11.png",
      "/images/VB-12.png",
    ],
  },
];

export default {
  projects,
};