import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./style/FontColors.scss";
import "./style/Menu.scss";

import menu from "../images/menu-fermer.svg";

class Menu extends Component {
  render() {
    return (
      <section id="MenuOpen" className={this.props.showMenu ? "active" : null}>
        {this.props.showMenu && (
          <div className="menuop">
            <div className="contact">
              <div className="buttons">
                <a href="mailto:laura.stromboni@gmail.com">
                  <button
                    className="span"
                    onClick={() => this.props.toggleFunction()}
                  >
                    contact
                  </button>
                </a>
                <Link to="/about">
                  <button
                    className="span"
                    onClick={() => this.props.toggleFunction()}
                  >
                    about
                  </button>
                </Link>
              </div>
              <div className="content">
                <h2>
                  i create <br />
                  <span>sensitive</span> & smart <br />
                  design.
                </h2>
              </div>
            </div>
            <div className="menu" onClick={() => this.props.toggleFunction()}>
              <img src={menu} className="menu-img" alt="logo" />
            </div>
          </div>
        )}
      </section>
    );
  }
}

export default Menu;
