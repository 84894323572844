import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import Header from "./components/Header.js";
import Homepage from "./components/Homepage.js";
import Menu from "./components/Menu.js";
import ProjectList from "./components/ProjectList.js";
import ProjectDetails from "./components/ProjectDetails.js";
import About from "./components/About.js";
import NotFound from "./components/NotFound.js";

import "./components/style/FontColors.scss";
import "./components/style/App.scss";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false
    };
  }

  toggleMenu() {
    const { showMenu } = this.state;
    this.setState({ showMenu: !showMenu });
  }

  checkMenu = () => {
    if (this.state.showMenu) {
      this.setState({ showMenu: false });
    }
  };

  render() {
    return (
      <div id="App">
        <Menu
          showMenu={this.state.showMenu}
          toggleFunction={() => this.toggleMenu()}
        />
        <Header toggleFunction={() => this.toggleMenu()} />
        <main onClick={this.checkMenu}>
          <Switch>
            <Route exact path="/" component={Homepage} />
            <Route path="/about" component={About} />
            {/* keep the projectId url BEFORE the projects one to make the page details appear */}
            <Route path="/projects/:projectId" component={ProjectDetails} />
            <Route path="/projects" component={ProjectList} />
            <Route path="/webdesign" component={ProjectList} />
            <Route path="/branding" component={ProjectList} />
            <Route path="/edition" component={ProjectList} />
            <Route path="/illustration" component={ProjectList} />
            <Route path="/tattoo" component={ProjectList} />
            <Route path="/print" component={ProjectList} />
            <Route path="/artdirection" component={ProjectList} />
            <Route path="/app" component={ProjectList} />
            <Route component={NotFound} />
          </Switch>
        </main>
      </div>
    );
  }
}

export default App;
