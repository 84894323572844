import React, {Component} from 'react';
import { Link } from 'react-router-dom';

import './style/NotFound.scss';

import iconDefault from '../images/pagenotfound.gif';

class NotFound extends Component {

  componentDidMount(){
    window.scrollTo(0,0)
  }

    render(){
        return(
            <section id = "NotFound">
                <img src={iconDefault} className="icon" alt="icon"/>
                <h1>Page not found</h1>
                <h2>sorry!</h2>
                <Link to="/"><button className="span">homepage</button></Link>
            </section>
        )
    }
}

export default NotFound;