import React, { Component } from "react";
import { Link } from "react-router-dom";

import projects from '../projects';

import "./style/ProjectList.scss";

import iconDefault from "../images/icon-default.svg";
import iconWeb from "../images/icon-webdesign.svg";
import iconBranding from "../images/icon-brandidentity.svg";
import iconArtD from "../images/icon-artdirection.svg";
import iconEd from "../images/icon-edition.svg";
import iconIll from "../images/icon-illustration.svg";
import iconTattoo from "../images/icon-tattoo.svg";
import iconPrint from "../images/icon-print.svg";
import iconApp from "../images/icon-app.svg";
import color from "../images/color.svg";

class ProjectList extends Component {
  constructor(props) {
    super(props);
    const category = this.props.location.pathname.slice(1);
    let projectArray = projects.projects.filter(elem => {
      return elem.category === category;
    });
    if(projectArray.length === 0){ projectArray = projects.projects}
    this.state = {
      projectArray: projectArray,
      loadingImage: true,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { projectArray } = this.state;
    const category = this.props.location.pathname.slice(1);
    return (
      <section id="ProjectList">
        <ul className="col-lg-12">
          {projectArray.map((oneProject) => {
            return (
              <li key={oneProject.id} className="col-lg-4 col-md-6 col-sm-12">
                <Link to={`/projects/${oneProject.id}`}>
                  <img
                    src={
                      this.state.loadingImage
                        ? oneProject.mainpicture
                        : require("../images/placeholder.jpg")
                    }
                    className="projectpic"
                    alt={oneProject.title}
                    placeholder={color}
                    loading="lazy"
                  />
                  <h2>{oneProject.title}</h2>
                  <p className="span">{oneProject.subtitle}</p>
                </Link>
              </li>
            );
          })}
        </ul>

        <div className="icondiv col-lg-1">
          {category === "illustration" ? (
            <img src={iconIll} alt="" className="icon" />
          ) : category === "branding" ? (
            <img src={iconBranding} alt="" className="icon" />
          ) : category === "webdesign" ? (
            <img src={iconWeb} alt="" className="icon" />
          ) : category === "edition" ? (
            <img src={iconEd} alt="" className="icon" />
          ) : category === "tattoo" ? (
            <img src={iconTattoo} alt="" className="icon" />
          ) : category === "print" ? (
            <img src={iconPrint} alt="" className="icon" />
          ) : category === "artdirection" ? (
            <img src={iconArtD} alt="" className="icon" />
          ) : category === "app" ? (
            <img src={iconApp} alt="" className="icon" />
          ) : (
            <img src={iconDefault} alt="" className="icon" />
          )}
        </div>
      </section>
    );
  }
}

export default ProjectList;
