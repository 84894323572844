import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import iconDefault from "../images/icon-default.svg";
import iconTattoo from "../images/icon-tattoo.svg";
import iconWeb from "../images/icon-webdesign.svg";
import iconBranding from "../images/icon-brandidentity.svg";
import iconArtD from "../images/icon-artdirection.svg";
import iconEd from "../images/icon-edition.svg";
import iconIll from "../images/icon-illustration.svg";
import iconPrint from "../images/icon-print.svg";
import iconApp from "../images/icon-app.svg";

import bgDefault from "../images/Sensitive-item.png";
import bgWeb from "../images/Webdesign-Roar-2.jpg";
import bgBranding from "../images/Logo_Yo-mockup.jpg";
import bgEdition from "../images/Book-MDV-1.jpg";
import bgArtD from "../images/ArtDir-Audience.jpg";
import bgIll from "../images/Drawing-kamasutragame.jpg";
import bgTattoo from "../images/Flash-Love.jpg";
import bgPrint from "../images/Print-Belaych-2.jpg";
import bgApp from "../images/App-We.jpg";

import "./style/FontColors.scss";
import "./style/Homepage.scss";

const images = {
  webdesign: { icon: iconWeb, bg: bgWeb },
  branding: { icon: iconBranding, bg: bgBranding },
  artdirection: { icon: iconArtD, bg: bgArtD },
  edition: { icon: iconEd, bg: bgEdition },
  illustration: { icon: iconIll, bg: bgIll },
  tattoo: { icon: iconTattoo, bg: bgTattoo },
  print: { icon: iconPrint, bg: bgPrint },
  app: { icon: iconApp, bg: bgApp },
};

class Homepage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      icon: null,
      bg: null,
    };
  }

  componentDidMount(name) {
    this.setState(images[name]);
  }

  mouseOut() {
    this.setState({
      icon: null,
      bg: null,
    });
  }

  mouseOver(name) {
    this.setState(images[name]);
  }

  render() {
    return (
      <div id="Homepage">
        <div className="content">
          <div className="Home-left col-lg-5 col-md-5">
            {this.state.icon ? (
              <img
                src={this.state.icon}
                className="icon icon-tattoo"
                alt="icon"
              />
            ) : (
              <img src={iconDefault} className="icon" alt="icon" />
            )}
            <ul>
            <NavLink
                to={{
                  pathname: "/illustration",
                  state: { category: "illustration" },
                }}
              >
                <li>
                  <h1
                    onMouseOut={() => this.mouseOut()}
                    onMouseOver={() => this.mouseOver("illustration")}
                  >
                    illustration
                  </h1>
                </li>
              </NavLink>
              <NavLink
                to={{ pathname: "/branding", state: { category: "branding" } }}
              >
                <li>
                  <h1
                    onMouseOut={() => this.mouseOut()}
                    onMouseOver={() => this.mouseOver("branding")}
                  >
                    brand identity
                  </h1>
                </li>
              </NavLink>
              <NavLink
                to={{
                  pathname: "/webdesign",
                  state: { category: "webdesign" },
                }}
              >
                <li>
                  <h1
                    onMouseOut={() => this.mouseOut()}
                    onMouseOver={() => this.mouseOver("webdesign")}
                  >
                    web design
                  </h1>
                </li>
              </NavLink>
              <NavLink
                to={{
                  pathname: "/edition",
                  state: { category: "edition" },
                }}
              >
                <li>
                  <h1
                    onMouseOut={() => this.mouseOut()}
                    onMouseOver={() => this.mouseOver("edition")}
                  >
                    edition
                  </h1>
                </li>
              </NavLink>
              <NavLink
                to={{ pathname: "/tattoo", state: { category: "tattoo" } }}
                onMouseOut={() => this.mouseOut()}
                onMouseOver={() => this.mouseOver("tattoo")}
              >
                <li>
                  <h1>tattoo</h1>
                </li>
              </NavLink>
              <NavLink
                to={{ pathname: "/print", state: { category: "print" } }}
              >
                <li>
                  <h1
                    onMouseOut={() => this.mouseOut()}
                    onMouseOver={() => this.mouseOver("print")}
                  >
                    print
                  </h1>
                </li>
              </NavLink>
              <NavLink
                to={{
                  pathname: "/artdirection",
                  state: { category: "artdirection" },
                }}
              >
                <li>
                  <h1
                    onMouseOut={() => this.mouseOut()}
                    onMouseOver={() => this.mouseOver("artdirection")}
                  >
                    art direction
                  </h1>
                </li>
              </NavLink>
              <NavLink
                to={{
                  pathname: "/app",
                  state: { category: "app" },
                }}
              >
                <li>
                  <h1
                    onMouseOut={() => this.mouseOut()}
                    onMouseOver={() => this.mouseOver("app")}
                  >
                    app
                  </h1>
                </li>
              </NavLink>
            </ul>
          </div>

          <div className="Home-right col-lg-7 col-md-7">
            {this.state.bg ? (
              <img
                src={this.state.bg}
                alt="bg"
                className="bg-tattoo"
                loading="lazy"
              />
            ) : (
              <img src={bgDefault} alt="bg" className="bg" loading="lazy" />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Homepage;
